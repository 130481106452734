import React from "react";
import { useTranslation } from "react-i18next";
import { Redirect, useParams } from "react-router-dom";
import PageHeader from "../../components/Headers/PageHeader";
import { useClientConfiguration } from "../../data/global/useProductConfiguration";
import useGetRole, { FRM_ROLE } from "../../hooks/useGetRole";
import { useClientRoutesAndLabels } from "../../ui/routes";

const HowTo: React.FC = () => {
  const [t] = useTranslation();
  const { client } = useParams<{ client: string }>();
  const { howToVideo, hideHowToVideoFromFrm } = useClientConfiguration(client);
  const routes = useClientRoutesAndLabels(client);
  const { data: role } = useGetRole(client);
  const isFrm = String(role) === FRM_ROLE;

  if (!howToVideo || (isFrm && hideHowToVideoFromFrm)) {
    return <Redirect to={routes.client.dpp.payers.with({ client })} />;
  }

  return (
    <div>
      <PageHeader title={t("howTo.header")} />
      <div className="pt-6">
        {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
        <video controls={true}>
          <source src={`/assets/video/${howToVideo}`} />
        </video>
      </div>
    </div>
  );
};

export default HowTo;
