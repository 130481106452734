import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { NumberFormatProps } from "react-number-format";
import MinMaxInputs from "./MinMaxInput";
import OneInput from "./OneInput";

interface Props extends NumberFormatProps {
  label?: string;
  range: Range;
  setRange: (range: Range) => void;
}

export interface Range {
  rangeType: RangeType;
  min?: number | null;
  max?: number | null;
}

export enum RangeType {
  BETWEEN = "between",
  LT = "lt",
  LE = "le",
  EQ = "eq",
  GT = "gt",
  GE = "ge",
}

export type Ranges = Record<RangeType, { short: string; long: string }>;

const adjustmentPrecision = 0.00001;

const RangeInput: React.FC<Props> = ({ label, range, setRange, ...inputProps }) => {
  const [t] = useTranslation();

  //Determine the starting amount based on the passed in min/max
  const initAmount = () => {
    if (range.rangeType === RangeType.LT && range.max) {
      return range.max + adjustmentPrecision;
    }
    if (range.rangeType === RangeType.GT && range.min) {
      return range.min - adjustmentPrecision;
    }
    return range.min ? range.min : range.max;
  };

  const [amount, setAmount] = useState<number | undefined | null>(() => initAmount());

  const ranges: Ranges = {
    between: {
      short: "...",
      long: t("ranges.between"),
    },
    lt: {
      short: "<",
      long: t("ranges.lessThan"),
    },
    le: {
      short: "≤",
      long: t("ranges.lessThanEqual"),
    },
    eq: {
      short: "=",
      long: t("ranges.equalTo"),
    },
    gt: {
      short: ">",
      long: t("ranges.greaterThan"),
    },
    ge: {
      short: "≥",
      long: t("ranges.greaterThanEqual"),
    },
  };

  //Update rangeType when select changes (between|lt|gt|eq...)
  const updateRangeType = (rangeType: RangeType) => {
    const newRange =
      rangeType === RangeType.BETWEEN ? { min: undefined, max: undefined, rangeType } : { ...range, rangeType };
    setRange(newRange);
    if (amount) {
      determineRange(amount, rangeType);
    } else {
      // In the case of there not being an amount, we need to clear the range
      setRange({ ...newRange, min: undefined, max: undefined });
    }
  };

  //Determine the filter range if only given one value based on the rangeType
  const determineRange: (newAmount: number, rangeType?: RangeType) => void = (
    newAmount,
    rangeType = range.rangeType,
  ) => {
    const amt = isNaN(newAmount) ? undefined : newAmount;
    const maxWithAdjustment = isNaN(newAmount) ? undefined : newAmount - adjustmentPrecision;
    const minWithAdjustment = isNaN(newAmount) ? undefined : newAmount + adjustmentPrecision;
    setAmount(amt);
    let newRange = undefined;
    switch (range.rangeType) {
      case RangeType.EQ:
        newRange = { ...range, min: amt, max: amt, rangeType };
        break;
      case RangeType.LT:
        newRange = { ...range, min: undefined, max: maxWithAdjustment, rangeType };
        break;
      case RangeType.LE:
        newRange = { ...range, min: undefined, max: amt, rangeType };
        break;
      case RangeType.GT:
        newRange = { ...range, min: minWithAdjustment, max: undefined, rangeType };
        break;
      case RangeType.GE:
        newRange = { ...range, min: amt, max: undefined, rangeType };
        break;
    }
    setRange(newRange as any);
  };

  return (
    <div className="flex-1 range-container">
      <label htmlFor={label} className="body-3">
        {label}
      </label>
      <div className="flex my-2">
        <div className={`flex flex-grow input-container my-0`}>
          <select
            value={range.rangeType}
            aria-label={t("ranges.selectRange")}
            id={label}
            onBlur={(e) => updateRangeType(e.target.value as RangeType)}
            onChange={(e) => updateRangeType(e.target.value as RangeType)}
            className="flex-grow-0 bg-white cursor-pointer border-steel focus:bg-primary focus:text-white border py-3 float-left focus:border-b focus:border-primary px-3 -ml-2 -mb-px"
          >
            {(Object.keys(ranges) as Array<RangeType>).map((rangeKey) => (
              <option key={rangeKey} value={rangeKey}>
                {ranges[rangeKey].short}
              </option>
            ))}
          </select>
          {range.rangeType === "between" ? (
            <MinMaxInputs range={range} setRange={setRange} {...inputProps} />
          ) : (
            <OneInput
              placeholder={ranges[range.rangeType].long}
              amount={amount}
              determineRange={determineRange}
              {...inputProps}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default RangeInput;
