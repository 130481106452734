import CircularProgress from "@material-ui/core/CircularProgress";
import React, { ComponentType } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { Redirect, Route, Switch, useParams } from "react-router";
import { usePayerLandscapeDetailDropdowns } from "../api/usePayerLandscapeDetailApi";
import { useClientPrintConfiguration } from "../data/global/useProductConfiguration";
import useDownloadZippedDocs from "../hooks/useDownloadZippedDocs";
import useGetRole, { BIOSIM_ROLE, FRM_ROLE, GENERAL_ROLE } from "../hooks/useGetRole";
import useHasHcpAccessAsFrm from "../hooks/useHasHcpAccessAsFrm";
import DefaultLayout from "../layouts/DefaultLayout";
import Email from "../pages/Email/Email";
import GeoLandscape from "../pages/GeoLandscape/GeoLandscape";
import GeoProfile from "../pages/GeoProfile/GeoProfile";
import HCPLandscape from "../pages/HCPLandscape/HCPLandscape";
import HCPProfile from "../pages/HCPProfile/HCPProfile";
import HowTo from "../pages/HowTo/HowTo";
import Messages from "../pages/Messages/Messages";
import PayerLandscape from "../pages/PayerLandscape/PayerLandscape";
import HealthcareProviders from "../pages/PlanProfile/HealthcareProviders";
import PlanProfile from "../pages/PlanProfile/PlanProfile";
import Queue from "../pages/Queue/Queue";
import Resources from "../pages/Resources/Resources";
import PageNotFound from "../pages/SystemError/PageNotFound";
import UserProfile from "../pages/UserProfile/UserProfile";
import { useClientRoutesAndLabels } from "./routes";

type WithRole = {
  allowedRoles?: string[];
};

const withRole =
  <P extends Record<string, unknown>>(
    Component: ComponentType<P>,
    options: WithRole = { allowedRoles: [FRM_ROLE, GENERAL_ROLE, BIOSIM_ROLE] },
  ): React.FC<P> =>
  (props: P): JSX.Element => {
    const { allowedRoles } = options;
    const { client: selectedClient } = useParams<{ client: string }>();
    const routes = useClientRoutesAndLabels(selectedClient);
    const { data: role, isIdle, isFetched, isFetching } = useGetRole(selectedClient);
    const hasRole = role && allowedRoles?.includes(String(role));

    if ((isIdle && !isFetched) || isFetching) {
      return (
        <div className="flex h-screen justify-center items-center">
          <CircularProgress size={80} />
        </div>
      );
    }

    if (!hasRole) {
      return <Redirect push to={routes.client.dpp.with({ client: selectedClient })} />;
    }

    return <Component {...props} />;
  };

const withGeneralUser = <P extends Record<string, unknown>>(Component: ComponentType<P>): React.FC<P> =>
  withRole(Component, {
    allowedRoles: [GENERAL_ROLE, BIOSIM_ROLE],
  });

const withGeneralAndHcpFrmUser = <P extends Record<string, unknown>>(
  Component: ComponentType<P>,
  hasHcpAccessAsFrm?: boolean,
): React.FC<P> => {
  return hasHcpAccessAsFrm
    ? withRole(Component, {
        allowedRoles: [GENERAL_ROLE, FRM_ROLE, BIOSIM_ROLE],
      })
    : withRole(Component, {
        allowedRoles: [GENERAL_ROLE, BIOSIM_ROLE],
      });
};

const withGeneralAndPrintFrmUser = <P extends Record<string, unknown>>(
  Component: ComponentType<P>,
  hasPrintAccessAsFrm?: boolean,
): React.FC<P> => {
  return hasPrintAccessAsFrm
    ? withRole(Component, {
        allowedRoles: [GENERAL_ROLE, FRM_ROLE, BIOSIM_ROLE],
      })
    : withRole(Component, {
        allowedRoles: [GENERAL_ROLE, BIOSIM_ROLE],
      });
};

const withPayerLandscapeDetailDropdowns =
  <P extends Record<string, unknown>>(Component: ComponentType<P>): React.FC<P> =>
  (props: P): JSX.Element => {
    const { client: selectedClient } = useParams<{ client: string }>();
    const { isIdle, isFetched, isFetching } = usePayerLandscapeDetailDropdowns(selectedClient);

    if ((isIdle && !isFetched) || isFetching) {
      return (
        <div className="flex h-screen justify-center items-center">
          <CircularProgress size={80} />
        </div>
      );
    }

    return <Component {...props} />;
  };

const DefaultLayoutRouter: React.FC = () => {
  const [t] = useTranslation();
  const { client: selectedClient } = useParams<{ client: string }>();
  const routes = useClientRoutesAndLabels(selectedClient);
  useDownloadZippedDocs(selectedClient);
  const printConfig = useClientPrintConfiguration(selectedClient);
  const { data: role, isFetched } = useGetRole(selectedClient);
  const isFRM = !isFetched || String(role) === FRM_ROLE;
  const hasHcpAccessAsFrm = useHasHcpAccessAsFrm(selectedClient, isFRM);

  return (
    <>
      <Helmet>
        <title>{t("defaultLayout.appName", { product: selectedClient?.split("_")?.join(" ") })}</title>
        <meta name="description" content={t("defaultLayout.appName", { product: selectedClient }) as string} />
      </Helmet>
      <DefaultLayout>
        <Switch>
          <Route path={routes.client.dpp.path} exact>
            <Redirect to={routes.client.dpp.payers.with({ client: selectedClient })} />
          </Route>
          <Route path={routes.client.dpp.geo.show.path} component={withGeneralUser(GeoProfile)} />
          <Route path={routes.client.dpp.geo.path} component={withGeneralUser(GeoLandscape)} />
          <Route path={routes.client.dpp.hcps.show.path} component={withGeneralUser(HCPProfile)} />
          <Route
            path={routes.client.dpp.hcps.path}
            component={withGeneralAndHcpFrmUser(HCPLandscape, hasHcpAccessAsFrm)}
          />
          <Route
            path={routes.client.dpp.payers.show.pinned.providers.path}
            component={withGeneralUser(HealthcareProviders)}
          />
          <Route path={routes.client.dpp.payers.show.providers.path} component={withGeneralUser(HealthcareProviders)} />
          <Route path={routes.client.dpp.payers.show.pinned.path} component={withGeneralUser(PlanProfile)} />
          <Route path={routes.client.dpp.payers.show.path} component={withGeneralUser(PlanProfile)} />
          <Route
            path={routes.client.dpp.payers.path}
            exact
            component={withRole(withPayerLandscapeDetailDropdowns(PayerLandscape))}
          />
          <Route path={routes.client.print.path} exact>
            <Redirect
              to={
                printConfig.hcpPrintMessages
                  ? routes.client.print.messages.with({ client: selectedClient })
                  : routes.client.print.resources.with({ client: selectedClient })
              }
            />
          </Route>
          <Route
            path={routes.client.print.messages.path}
            component={withGeneralAndPrintFrmUser(Messages, printConfig.printEnabledForFRM)}
          />
          <Route
            path={routes.client.print.resources.path}
            component={withGeneralAndPrintFrmUser(Resources, printConfig.printEnabledForFRM)}
          />
          <Route
            path={routes.client.print.queue.path}
            component={withGeneralAndPrintFrmUser(Queue, printConfig.printEnabledForFRM)}
          />
          <Route
            path={routes.client.print.email.path}
            component={withGeneralAndPrintFrmUser(Email, printConfig.printEnabledForFRM)}
          />
          <Route path={routes.client.profile.path} component={withRole(UserProfile)} />
          <Route path={routes.client.howTo.path} exact component={HowTo} />
          <Route component={PageNotFound} />
        </Switch>
      </DefaultLayout>
    </>
  );
};

export default DefaultLayoutRouter;
