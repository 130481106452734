/* eslint-disable @typescript-eslint/no-explicit-any */
import InfoOutlined from "@material-ui/icons/InfoOutlined";
import ExportIcon from "@material-ui/icons/OpenInNew";
import WarningIcon from "@material-ui/icons/Warning";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { usePayerProfileTopHCPExport } from "../../api/usePayerProfileApi";
import TableHeader from "../../components/Headers/TableHeader";
import SimpleTable from "../../components/Table/SimpleTable";
import { Column } from "../../components/Table/types";
import Tooltip from "../../components/Tooltip/Tooltip";
import { useClientPlanProfileTopHCPConfiguration } from "../../data/global/useProductConfiguration";
import {
  TopHealthcareProviders as ApiResponse,
  TopHealthcareProviders as TopHealthcareProvidersType,
} from "../../generate";
import { useClientRoutesAndLabels } from "../../ui/routes";

interface Props {
  channel: string;
  hcpData: ApiResponse[] | undefined | null;
  loading: boolean;
  error?: null | Error;
  stateInformation: {
    client: string;
    id: string;
    timePeriod?: string | undefined;
    indication?: string | undefined;
  };
}

const TopHealthcareProviders: React.FC<Props> = ({ channel, hcpData, loading, error, stateInformation }) => {
  const { t, i18n } = useTranslation();

  const selectedClient = stateInformation.client;

  const routes = useClientRoutesAndLabels(selectedClient);

  const { id, hcpID } = useParams<{ id: string; hcpID: string | undefined }>();

  const { dataKeys: allowedDataKeys } = useClientPlanProfileTopHCPConfiguration(selectedClient);

  const HealthcareProviderName = "healthcareProviderName" as const;
  const availableColumns: Column[] = useMemo(
    () => [
      {
        label: t([`planProfile.topHcps.${selectedClient}`, `planProfile.topHcps.default`]),
        align: "left",
        dataKey: "topHCPs",
        tdClasses: "bg-light-blue",
        component: HealthcareProviderName,
      },
      {
        label: i18n.exists(`planProfile.topHcpColumnHeaders.${selectedClient}.fav_pct`)
          ? t(`planProfile.topHcpColumnHeaders.${selectedClient}.fav_pct`)
          : t("planProfile.topHcpColumnHeaders.fav_pct"),
        align: "right",
        dataKey: "fav_pct",
        format: {
          suffix: "%",
          decimalScale: 1,
          fixedDecimalScale: true,
        },
      },
      {
        label: i18n.exists(`planProfile.topHcpColumnHeaders.${selectedClient}.acc_pct`)
          ? t(`planProfile.topHcpColumnHeaders.${selectedClient}.acc_pct`)
          : t("planProfile.topHcpColumnHeaders.acc_pct"),
        align: "right",
        dataKey: "acc_pct",
        format: {
          suffix: "%",
          decimalScale: 1,
          fixedDecimalScale: true,
        },
      },
      {
        label: t("planProfile.topHcpColumnHeaders.bobPctOvrl", { product: selectedClient }),
        align: "right",
        dataKey: "bob_pct_ovrl",
        format: {
          suffix: "%",
          decimalScale: 1,
          fixedDecimalScale: true,
        },
      },
      {
        label: i18n.exists(`planProfile.topHcpColumnHeaders.${selectedClient}.prod_trx`)
          ? t(`planProfile.topHcpColumnHeaders.${selectedClient}.prod_trx`, { product: selectedClient })
          : t("planProfile.topHcpColumnHeaders.prod_trx", { product: selectedClient }),
        align: "right",
        dataKey: "prod_trx",
        format: {
          thousandSeparator: true,
        },
      },
      {
        label: i18n.exists(`planProfile.topHcpColumnHeaders.${selectedClient}.marketTrx`)
          ? t(`planProfile.topHcpColumnHeaders.${selectedClient}.marketTrx`)
          : t(`planProfile.topHcpColumnHeaders.marketTrx`),
        align: "right",
        dataKey: "mkt_trx",
        format: {
          thousandSeparator: true,
        },
      },
      {
        label: t("planProfile.topHcpColumnHeaders.prod_share", { product: selectedClient }),
        align: "right",
        dataKey: "prod_share",
        format: {
          suffix: "%",
          decimalScale: 1,
          fixedDecimalScale: true,
        },
      },
      {
        label: i18n.exists(`planProfile.topHcpColumnHeaders.${selectedClient}.chnl_prod_trx`)
          ? t(`planProfile.topHcpColumnHeaders.${selectedClient}.chnl_prod_trx`, { product: selectedClient, channel })
          : t("planProfile.topHcpColumnHeaders.chnl_prod_trx", { product: selectedClient, channel }),
        align: "right",
        dataKey: "chnl_prod_trx",
        format: {
          thousandSeparator: true,
        },
      },
      {
        label: i18n.exists(`planProfile.topHcpColumnHeaders.${selectedClient}.comp_prod_trx`)
          ? t(`planProfile.topHcpColumnHeaders.${selectedClient}.comp_prod_trx`, { product: selectedClient, channel })
          : t("planProfile.topHcpColumnHeaders.comp_prod_trx", { product: selectedClient, channel }),
        align: "right",
        dataKey: "comp_prod_trx",
        format: {
          thousandSeparator: true,
        },
      },
      {
        label: i18n.exists(`planProfile.topHcpColumnHeaders.${selectedClient}.comp_prod_trx`)
          ? t(`planProfile.topHcpColumnHeaders.${selectedClient}.comp_prod_trx`, { product: selectedClient, channel })
          : t("planProfile.topHcpColumnHeaders.comp_prod_trx", { product: selectedClient, channel }),
        align: "right",
        dataKey: "chnl_comp_trx",
        format: {
          thousandSeparator: true,
        },
      },
      {
        label: i18n.exists(`planProfile.topHcpColumnHeaders.${selectedClient}.chnl_mkt_trx`)
          ? t(`planProfile.topHcpColumnHeaders.${selectedClient}.chnl_mkt_trx`, { channel })
          : t("planProfile.topHcpColumnHeaders.chnl_mkt_trx", { channel }),
        align: "right",
        dataKey: "chnl_mkt_trx",
        format: {
          thousandSeparator: true,
        },
      },
      {
        label: i18n.exists(`planProfile.topHcpColumnHeaders.${selectedClient}.chnl_prod_share`)
          ? t(`planProfile.topHcpColumnHeaders.${selectedClient}.chnl_prod_share`, { product: selectedClient, channel })
          : t("planProfile.topHcpColumnHeaders.chnl_prod_share", { product: selectedClient, channel }),
        align: "right",
        dataKey: "chnl_prod_share",
        format: {
          suffix: "%",
          decimalScale: 1,
          fixedDecimalScale: true,
        },
      },
    ],
    [channel, selectedClient, t, i18n],
  );
  const columns: Column[] = useMemo(() => {
    return availableColumns.filter(({ dataKey }) =>
      allowedDataKeys?.includes(dataKey as keyof TopHealthcareProvidersType),
    );
  }, [availableColumns, allowedDataKeys]);
  const columnKeys = columns.map((column) => column.dataKey);
  const columnNames = columns.map((column) => t(column.label, column.translationParams));

  const exportTable = usePayerProfileTopHCPExport(
    stateInformation.id,
    columnKeys,
    columnNames,
    stateInformation.client,
    20,
    stateInformation.timePeriod,
    stateInformation.indication,
  );

  return (
    <div className="pb-6">
      <TableHeader
        title={
          i18n.exists(`planProfile.topProvider.${selectedClient}`)
            ? t(`planProfile.topProvider.${selectedClient}`)
            : t(`planProfile.topProvider.default`)
        }
      >
        <Tooltip
          placement="bottom-end"
          tip={t([`planProfile.topProviderTooltip.${selectedClient}`, `planProfile.topProviderTooltip.default`])}
        >
          <InfoOutlined className="text-primary" />
        </Tooltip>
      </TableHeader>
      <SimpleTable
        columns={columns}
        data={hcpData}
        loading={loading}
        tableFooter={
          <tfoot>
            <tr>
              <td colSpan={10} className="sm:text-right p-0">
                <div className="bg-rules flex items-center justify-between">
                  <Link
                    to={
                      hcpID
                        ? routes.client.dpp.payers.show.pinned.providers.with({ client: selectedClient, id, hcpID })
                        : routes.client.dpp.payers.show.providers.with({ client: selectedClient, id: id })
                    }
                    className="btn btn-primary w-9/12 m-1.5"
                  >
                    <span>{t("planProfile.viewAllHcps")}</span>
                  </Link>
                  <div className="flex">
                    <button className="btn p-0" disabled={exportTable.isLoading} onClick={() => exportTable.mutate()}>
                      {(exportTable.status === "idle" || exportTable.status === "success") && (
                        <span>{t("common.export")}</span>
                      )}
                      {exportTable.status === "loading" && <span>{t("common.exportLoading")}</span>}
                      {exportTable.status === "error" && (
                        <>
                          <WarningIcon /> <span>{t("common.export")}</span>
                        </>
                      )}
                    </button>

                    <button
                      className="btn p-0"
                      aria-label={t("common.export")}
                      disabled={exportTable.isLoading}
                      onClick={() => exportTable.mutate()}
                    >
                      <ExportIcon />
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          </tfoot>
        }
      />
    </div>
  );
};

export default TopHealthcareProviders;
